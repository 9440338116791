import React from "react";
import Layout from "../../../../components/Layout";
import Seo from "../../../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import ogIMage from "../../../../images/og.png";

const OurStoryImpactTwo = () => {
    return (
        <Layout>
            <Seo
                title={"Creating safe spaces for the elementary school"}
                description="Explore our impactful initiative in collaboration with local communities in Karur, dedicated to creating safe spaces and fostering dreams for school children. "
                image={ogIMage}
            />
            <section className="csr-our-stories">
                <div className="container container--first-v3">
                    <div className="csr-our-stories__text gap-2x">
                        <div className="text-p3 gap-2x csr-our-stories__text-breadcrumbs text-fw-normal">
                            <Link to={`/about/csr`} className="text-decor-none">
                                CSR
                            </Link>
                            /<Link className="text-decore none">Our Stories Of Impact</Link>
                        </div>
                        <h1 className="text-h1 text-clr-primary text-fw-medium gap-2x">
                            Creating Safe Spaces, Fostering Dreams: Sedin Technologies Empowers
                            School Children in Karur
                        </h1>
                        <div className="csr-our-stories__banner-img gap-3x">
                            <StaticImage
                                src="../../../../images/csrpage/our-stories-banner-img-2.png"
                                alt="banner-img"
                                loading="lazy"
                                placeholder="blurred"
                                className="banner-img"
                            />
                        </div>
                        <h2 className="text-h2 text-clr-primary text-fw-medium gap-1x">
                            Our Compassion in Action
                        </h2>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            Sedin Technologies is honored to have been able to play a role in
                            completing an infrastructure element for the elementary school in the
                            town of Karur, Tamil Nadu, as part of our CSR activities.
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            The project, which commenced in June 2023, was completed within the
                            agreed timelines, delivering a boost to the school's security
                            infrastructure.
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            Research has shown that schools with good infrastructure become spaces
                            that children can be proud of, and the completion of the compound wall
                            is a small gesture towards helping them see their school space as a
                            place where they are always welcome. 
                        </p>
                        <p className="text-p3 text-clr-secondary text-fw-medium gap-1x">
                            With a sense of joy, we are proud to have played a role in their lives,
                            and look forward to supporting them in future endeavours..
                        </p>
                    </div>
                </div>
            </section>
            {/* our-stories-image-gallery section */}
            <section className="csr-our-stories-img-gallery">
                <div className="container container--last">
                    <div className="csr-our-stories-img-gallery__grid">
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-02-1.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-02-2.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-02-3.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-02-4.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                        <div className="csr-our-stories-img-gallery__grid-item">
                            <StaticImage
                                src={`../../../../images/csrpage/our-stories-02-5.png`}
                                alt="img-gallery"
                                placeholder="blurred"
                                className="img-gallery"
                                layout="fullWidth"
                            />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
};

export default OurStoryImpactTwo;
